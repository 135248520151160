<template>
  <div>
    <PageHeader :items="items">
      <template #filters>
        <div class="search-filters">
          <SearchFilter :isFilterSearch="false">
            <li>
              <div class="form-group">
                <label class="input-label form-label"
                  >{{ $t("Customer") }}
                </label>
                <MultiSelectInput
                  v-model="form.companyId"
                  :options="customers"
                  label="companyName"
                  trackBy="id"
                  moduleName="customers"
                  :multiple="false"
                  ><template #option="{ props }">
                    <div>
                      <p>
                        {{ props.option.companyNumber }} -
                        {{ props.option.companyName }}
                      </p>
                    </div>
                  </template>
                </MultiSelectInput>
              </div>
            </li>
          </SearchFilter>
          <div class="page-header-search">
            <input
              autocomplete="off"
              type="text"
              name="search"
              :placeholder="$t('Search…')"
              v-model="form.search"
            />
            <button class="" type="button" @click="form.search = ''">
              {{ $t("Reset") }}
            </button>
          </div>
        </div>
      </template>
    </PageHeader>
    <div class="card">
      <div class="card-body">
        <b-row>
          <b-col lg="6">
            <div class="custom-search d-flex justify-content-between mb-1">
              <b-form-group class="m-0">
                <div class="d-flex align-items-center">
                  <span class="text-nowrap"> {{ $t("Rows per page") }} </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10', '25', '50', '100']"
                    class="ml-1"
                    @input="(value) => onPerPageChange({ pageLength: value })"
                  />
                </div>
              </b-form-group>
            </div>
            <!--==================================-->
            <!--==================================-->
            <div class="dextop-table-view">
              <div class="api-keys-table">
                <!-- table -->
                <vue-good-table
                  styleClass="vgt-table striped"
                  :columns="columns"
                  :fixed-header="false"
                  :rows="rows"
                  :search-options="{
                    enabled: true,
                    externalQuery: searchTerm,
                    trigger: 'enter',
                  }"
                  :pagination-options="{
                    enabled: false,
                    perPage: pageLength,
                  }"
                  mode="remote"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    class="vgt-center-align vgt-text-disabled"
                    slot="emptystate"
                  >
                    {{ $t("No data for table") }}
                  </div>
                  <template slot="table-row" slot-scope="props">
                    <!-- Column: Name -->
                    <div
                      v-if="props.column.field === 'check'"
                      class="d-flex align-items-center"
                    >
                      <div class="checkbox-group">
                        <input
                          type="checkbox"
                          class="checkbox-input"
                          :id="'check' + props.row.id"
                          :value="props.row.id"
                          @change="toggleSelection(props.row.id)"
                          :checked="selectedRows.includes(props.row.id)"
                        />
                        <label
                          :for="'check' + props.row.id"
                          class="checkbox-label"
                        ></label>
                      </div>
                    </div>
                    <span
                      class="d-flex align-items-center justify-content-center gap-2"
                      v-if="props.column.field === 'action'"
                    >
                      <div
                        v-if="$can(`${$route.meta.permission}.edit`)"
                        class="cursor-pointer"
                        @click="toggleEditModal(props.row)"
                      >
                        <feather-icon size="16" icon="Edit2Icon" />
                      </div>
                    </span>
                    <span v-else-if="props.column.field === 'street'">
                      {{ props.row.street }}, {{ props.row.zipCode }}
                      {{ props.row.city }}, {{ props.row.country }}
                    </span>
                    <span
                      v-else-if="props.column.field === 'Company.companyName'"
                    >
                      {{ props.row.companyName }}
                    </span>
                    <!-- Column: Common -->
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>
                </vue-good-table>
              </div>
            </div>
            <!--==================================-->
            <!--==================================-->
            <div class="reponsive-view-cards">
              <b-row>
                <b-col
                  cols="12"
                  lg="4"
                  md="6"
                  v-for="row in rows"
                  :key="row.id"
                >
                  <div class="card mb-1">
                    <div class="card-header">
                      <div class="checkbox-group">
                        <input
                          type="checkbox"
                          class="checkbox-input"
                          :id="'check' + row.id"
                          :value="row.id"
                          @change="toggleSelection(row.id)"
                          :checked="selectedRows.includes(row.id)"
                        />
                        <label
                          :for="'check' + row.id"
                          class="checkbox-label"
                        ></label>
                      </div>
                      <div
                        v-if="$can(`${$route.meta.permission}.edit`)"
                        class="card-action"
                      >
                        <feather-icon
                          size="16"
                          icon="Edit2Icon"
                          @click="toggleEditModal(row)"
                        />
                      </div>
                    </div>

                    <div class="card-body pt-0">
                      <div class="card-item">
                        <strong>{{ $t("Unit") }}:</strong> {{ row.unit }}
                      </div>
                      <div class="card-item">
                        <strong>{{ $t("Location") }}:</strong>
                        {{ row.street }}, {{ row.zipCode }} {{ row.city }},
                        {{ row.country }}
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
            <!--==================================-->
            <!--==================================-->
            <div class="d-flex align-items-center justify-content-center mt-1">
              <b-pagination
                :value="1"
                :total-rows="totalRecords"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => onPageChange({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
            <div
              class="d-flex align-items-center justify-content-end mt-2 mb-1"
            >
              <button
                v-if="$can(`${$route.meta.permission}.delete`)"
                @click="deleteUnits"
                :disabled="selectedRows.length == 0"
                class="btn btn-secondary mr-1"
              >
                {{ $t("Delete Unit") }}
              </button>
              <button
                v-if="$can(`${$route.meta.permission}.create`)"
                :class="{ 'cursor-not-allowed': isFormInvalid }"
                @click="storeUnit"
                class="btn btn-primary"
              >
                {{ $t("Add Unit") }}
              </button>
            </div>
          </b-col>
          <b-col lg="6" v-if="$can(`${$route.meta.permission}.create`)">
            <validation-observer ref="simpleRules">
              <div class="e-card">
                <div class="e-card-body">
                  <b-row>
                    <b-col lg="12">
                      <validation-provider
                        #default="{ errors }"
                        name="Organizational Unit"
                        rules="required"
                      >
                        <TextInput
                          :label="$t('Organizational Unit')"
                          :type="'text'"
                          v-model="unit.unit"
                          :placeholder="''"
                          :required="true"
                        />
                        <small class="text-danger">{{ $t(errors[0]) }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col lg="12">
                      <TextInput
                        :label="$t('Street')"
                        :type="'text'"
                        v-model="unit.street"
                        :placeholder="''"
                      />
                    </b-col>
                    <b-col lg="12">
                      <TextInput
                        :label="$t('Additional Information')"
                        :type="'text'"
                        v-model="unit.additionalInformation"
                        :placeholder="''"
                      />
                    </b-col>
                    <b-col lg="12">
                      <div class="row">
                        <div class="col-3">
                          <TextInput
                            :label="$t('Zip Code')"
                            :type="'text'"
                            v-model="unit.zipCode"
                            :placeholder="''"
                          />
                        </div>
                        <div class="col-9">
                          <TextInput
                            :label="$t('City')"
                            :type="'text'"
                            v-model="unit.city"
                            :placeholder="''"
                          />
                        </div>
                      </div>
                    </b-col>
                    <b-col lg="6">
                      <div class="form-group">
                        <label class="form-label input-label" for="country">{{
                          $t("Country")
                        }}</label>
                        <multi-select
                          :multiple="false"
                          label="name"
                          track-by="name"
                          :options="countries"
                          v-model="unit.country"
                          :placeholder="$t('Select Option')"
                        />
                      </div>
                    </b-col>
                    <b-col lg="6">
                      <div class="form-group">
                        <label
                          class="form-label input-label"
                          for="address_for_invoices"
                          >{{ $t("Address for Invoices") }}</label
                        >
                        <multi-select
                          :multiple="false"
                          label="name"
                          track-by="name"
                          :options="[]"
                          :placeholder="$t('Select Option')"
                        />
                      </div>
                    </b-col>
                    <b-col lg="6">
                      <TextInput
                        :label="$t('Contact Person')"
                        :type="'text'"
                        :placeholder="''"
                        v-model="unit.contactPerson"
                      />
                    </b-col>
                    <b-col lg="6">
                      <TextInput
                        :label="$t('E-Mail Contact Person')"
                        :type="'text'"
                        v-model="unit.contactPersonEmail"
                        :placeholder="''"
                      />
                    </b-col>
                  </b-row>
                </div>
              </div>
            </validation-observer>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="c-modal" v-show="PreviewEditModal">
      <div class="c-modal-content">
        <div class="c-modal-header">
          <h3>{{ $t("Edit") }}</h3>
          <div class="x-icon" @click="toggleEditModal">
            <feather-icon size="16" icon="XIcon" />
          </div>
        </div>
        <div class="c-modal-body h-100 p-2">
          <EditModal
            v-if="PreviewEditModal"
            :data="editUnitForm"
            @closeEditModal="handleCloseEditModal"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import { mapGetters } from "vuex";
import NotificationService from "../../services/notification.service";
import MultiSelect from "vue-multiselect";
import countries from "@/assets/data/countries.json";
import TextInput from "@/components/TextInput.vue";
import MultiSelectInput from "@/components/MultiSelectInput.vue";
import EditModal from "./components/EditModal.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { debounce } from "@/utils/debounce.js";
import SearchFilter from "@/components/SearchFilter.vue";
export default {
  components: {
    PageHeader,
    MultiSelect,
    TextInput,
    MultiSelectInput,
    EditModal,
    ValidationProvider,
    ValidationObserver,
    SearchFilter,
  },
  computed: {
    ...mapGetters("customers", ["customers"]),
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },

        {
          text: this.$t("Units"),
          active: true,
        },
      ];
    },
    columns() {
      return [
        {
          label: "",
          field: "check",
          sortable: false,
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Unit"),
          field: "unit",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Company"),
          field: "Company.companyName",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Location"),
          field: "street",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Action"),
          field: "action",
          sortable: false,
        },
      ];
    },
    isFormInvalid() {
      return !this.unit.unit;
    },
  },
  data() {
    return {
      selectedRows: [], // Array to hold selected row IDs

      countries,
      totalRecords: 0,
      pageLength: 10,
      page: 1,
      form: {
        search: "",
        companyId: "",
      },
      sortBy: "createdAt",
      sortOrder: "desc",
      unit: {
        unit: "",
        street: "",
        additionalInformation: "",
        zipCode: "",
        city: "",
        country: null,
        contactPerson: "",
        contactPersonEmail: "",
      },
      rows: [],
      searchTerm: "",
      PreviewEditModal: false,
      editUnitForm: {
        unit: "",
        street: "",
        additionalInformation: "",
        zipCode: "",
        city: "",
        country: null,
        contactPerson: "",
        contactPersonEmail: "",
      },
    };
  },
  watch: {
    form: {
      handler() {
        this.debouncedFetch();
      },
      deep: true,
    },
  },
  async created() {
    this.debouncedFetch = debounce(async (newValue, oldValue) => {
      try {
        await this.loadItems();
      } catch (e) {
        console.error(e);
      }
    }, 300);
    await this.loadItems();
    await this.$store.dispatch("customers/list");
  },
  methods: {
    handleCloseEditModal() {
      this.PreviewEditModal = false;
      this.loadItems();
    },
    toggleEditModal(data) {
      this.PreviewEditModal = !this.PreviewEditModal;
      if (this.PreviewEditModal) {
        this.editUnitForm = data;
      }
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this, newProps);
    },

    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
    toggleSelection(rowId) {
      const index = this.selectedRows.indexOf(rowId);
      if (index > -1) {
        // If the ID is already selected, remove it
        this.selectedRows.splice(index, 1);
      } else {
        // If the ID is not selected, add it
        this.selectedRows.push(rowId);
      }
    },
    onPerPageChange(params) {
      this.updateParams({ pageLength: params.pageLength });
      this.loadItems();
    },

    onSortChange(params) {
      this.sortOrder = params[0].type;
      if (params[0].type == "none") this.sortOrder = "asc";
      this.sortBy = params[0].field;
      this.loadItems();
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        this.$store.commit("showLoader", true);
        let response = await this.$store.dispatch("units/list", {
          page: this.page,
          ...this.form,
          perPage: this.pageLength,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder,
          companyId: this.form.companyId?.id ?? "",
        });
        this.rows = response?.data?.data;
        this.totalRecords = response?.data?.total;
      } catch (e) {
        console.error("api error ___", e);
      } finally {
        this.$store.commit("showLoader", false);
      }
    },
    async storeUnit() {
      try {
        this.$refs.simpleRules.validate().then(async (success) => {
          if (success) {
            await this.$store.dispatch("units/create", {
              ...this.unit,
              country: this.unit.country?.name ?? "",
            });
            this.resetForm();
            this.loadItems();
          }
        });
      } catch (e) {
        console.error("api error", e);
      }
    },
    resetForm() {
      this.$refs.simpleRules.reset();
      this.unit = {
        unit: "",
        street: "",
        additionalInformation: "",
        zipCode: "",
        city: "",
        country: null,
        contactPerson: "",
        contactPersonEmail: "",
      };
    },
    async deleteUnits() {
      const confirmed = await this.showConfirmationDialog({
        title: this.$t("Do you want to delete these record?"),
        subText: this.$t("Yes delete them!"),
      });

      if (confirmed) {
        await this.$store
          .dispatch("units/deleteUnits", {
            ids: this.selectedRows,
          })
          .finally(() => {
            this.selectedRows = [];
            this.loadItems();
          });
      }
    },
  },
};
</script>

<style>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
